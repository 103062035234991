
/*
    This file provides additional functionality to i18next's language detectors.

    https://github.com/i18next/i18next-http-middleware#adding-own-detection-functionality
    https://github.com/i18next/i18next-browser-languageDetector#adding-own-detection-functionality
*/

/* eslint-env browser */
const
    config = require('rocketship-config'),
    { defaultLocale, pairs } = {"_public":true,"enable":true,"defaultLocale":"en-US","pairs":[{"pattern":"(fr|jeudetestarbucks\\.ca|-frca)","locale":"fr-CA"},{"pattern":"(\\.ca|-ca)","locale":"en-CA"}]};

const regexToLocale = new Map(
    pairs.map(pair => [new RegExp(pair.pattern), pair.locale]),
);

function getLocaleFromVanity (domain) {
    for (const [regex, locale] of regexToLocale.entries()) {
        if (regex.test(domain)) {
            return locale;
        }
    }

    return defaultLocale;
}

// Included in app\lib\i18next.js
const expressDetector = {
    name: 'vanity',

    lookup (req, res, lng, options) {
        return getLocaleFromVanity(req.get('X-Forwarded-Host') || req.get('Host'));
    },
};

// Included in app\public\js\i18n\index.js to detect vanity on frontend
const browserDetector = {
    name: 'vanity',

    lookup (options) {
        return getLocaleFromVanity(window.location.hostname);
    },
};

module.exports = {
    expressDetector,
    browserDetector,
};
